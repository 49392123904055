import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide63/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide63/image/img.png"
import slider1 from "@components/pageGuide/guides/guide63/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide63/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide63/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Guía para automatizar la comunicación durante Lead Time en logística
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Automatizar la comunicación durante el Lead Time en logística es clave para optimizar procesos y mantener informados a los clientes. En esta guía, aprenderás cómo implementar sistemas que faciliten el flujo de información en cada etapa, desde la confirmación del pedido hasta la entrega.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Descubre las herramientas que permiten mejorar la eficiencia en la comunicación, asegurando que todos los involucrados estén al tanto del estado de los envíos.
    <br /><br />
    Esta información te ayudará a gestionar mejor los tiempos y a mejorar la experiencia de tus clientes.
  </p>
)

const data = {
  start: {
    support: "Guía | Logística ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Integración de sistemas internos con canales.",
      },
      {
        key: 2,
        text: "   Automatización en procesos de producción.",
      },
      {
        key: 3,
        text: " Planificación de demanda futura.",
      },
      {
        key: 4,
        text: " Identifiación de cuellos de botella.",
      },
      {
        key: 5,
        text: "Estrategia de abastecimiento.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
